import React from 'react'
import config from '../../../config'
import Layout from '../../../layouts/Layout';

const Privacy = () => {
	return (
		<Layout>
			<section className="privacy">
				<h1> "PRIVACY" </h1>
				<div>
					<p>
						THO, LLC values the importance of privacy and security for our
						visitors. Please take a moment to read this Privacy Statement, which
						describes how we use, disclose and protect the personal information
						that you share with us. "Personal Information" is information that
						enables us to identify you, such as your name and email address. This
						Privacy Statement applies to the website &nbsp;
						{config('APP_URL')} and to all web pages of {config('APP_URL')}
						(collectively, this "Site"). By using this Site, you consent to the
						policies and practices described in this Privacy Statement. If you do
						not agree with these terms, please discontinue your use of our Site.
					</p>
					<p>
						If we decide to change our Privacy Statement, we will post those
						changes on our Site so that you can always find out what information
						we gather about you and how we might use that information. If we make
						material changes to how we use your Personal Information, we shall
						notify you by means of a prominent notice on our Site. We encourage
						you to periodically review our Privacy Statement to stay informed
						about how we are using the Personal Information we collect.
					</p>
					<h2>SECURITY STATEMENT </h2>
					<p>
						When you visit {config('APP_URL')}, we respect your privacy.&nbsp;
						{config('APP_URL')} uses the information you provide us to help
						personalize product information and offers. We only use your name and
						address information to mail you promotional items, news and offers.
					</p>
					<h2> EMAIL PRIVACY MATTERS </h2>
					<p>
						We use your email address only to send you information and updates
						about {config('APP_URL')} and our partners. You can unsubscribe at any
						time you wish to be taken off the {config('APP_URL')} email list.
					</p>
					<h2>COOKIES AND YOUR COMPUTER </h2>
					<p>
						Like most e-commerce websites, we use "cookie" technology. A cookie is
						a very small text file that a website can save onto your computer's
						hard disk to store your information and settings. When you log on, the
						cookie tells us whether you have visited us before or are a new
						visitor. It doesn't track any personal information, give us the means
						to contact you, extract information from your computer or deliver
						viruses. Furthermore, your address or credit card information is never
						stored in a cookie. Cookies are simply the most efficient way for us
						to be able to offer you an enjoyable online shopping environment.
					</p>
					<h2> DISCLOSURE OF PERSONAL INFORMATION</h2>
					<p>
						Any information you provide to us when you establish or update an
						account, enter a contest, shop online or request information (i.e.
						name, address, email address, telephone number and credit card
						information), is maintained in private files on our secure server and
						our internal systems. This information is used to enable Alias
						Studios, LLC to deliver services and information about our products to
						you.
					</p>
					<p>
						To ensure compliance with federal law, {config('APP_URL')} does not
						maintain information provided by children under the age of 13. If you
						are visiting {config('APP_URL')} from a location outside the U.S.,
						your connection will be through and to servers located in the U.S.
					</p>
					<h2> LINKS </h2>
					<p>
						For your convenience, our website may contain links to other sites.{' '}
						{config('APP_URL')} is not responsible for the privacy practices or
						the content of such websites.
					</p>
					<h2>DISCLOSER OF INFORMATION TO OUTSIDE PARTIES </h2>
					<p>
						We do not sell, trade, or otherwise transfer to outside parties your
						personally identifiable information unless we provide you with advance
						notice, except as described below. It also does not include website
						hosting partners and other parties who assist us in operating our
						website, conducting our business, or servicing you, so long as those
						parties agree to keep this information confidential. We may also
						release your information when we believe release is appropriate to
						comply with the law, enforce our site policies, or protect ours or
						others' rights, property, or safety. However, non-personally
						identifiable visitor information may be provided to other parties for
						marketing, advertising, or other uses.
					</p>
					<h2>CONTACTING US</h2>
					<p>
						If you have any questions, comments or concerns about this Policy, you
						can send us an email at:
						<a href="mailto:Contact@THOagency.com">contact@THOagency.com</a>.
					</p>
				</div>
			</section>
		</Layout>
	)
}

export default Privacy
