import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import BaseLayout from '../layouts/BaseLayout';
import Privacy from '../components/pages/privacy';

const PrivacyPage = () => {
    useEffect(() => {
        document.body.style.height = ``
    }, []);
    return (
        <BaseLayout>
            <Helmet>
                <title>The House Of | Privacy</title>
            </Helmet>
            <Privacy/>
        </BaseLayout>
    )
}

export default PrivacyPage;
